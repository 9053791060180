import React, { useContext, useEffect, useState } from "react";
import { Header } from "components/common/header/Header";
import GridCard from "components/module/app/dashboard/GridCard";
import AvailableCourses from "components/module/app/myCourses/AvailableCourses";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import UserContext from "context/userContext/UserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import { SkeletonCard } from "components/common/skeleton/SkeletonCard";
import VideoPlayerYoutube from "components/common/videoPlayer/VideoPlayerYoutube";
import VideoPlayerDashboard from "components/common/videoPlayer/VideoPlayerDashboard";

const DashboardPage = () => {
  //user context
  const { userData, isLoading } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [loading, setLoading] = useState(true);

  const [courseData, setCourseData] = useState({});

  //initiate the custom post req
  const {
    data: recommendedCoursesData,
    error: recommendedCoursesError,
    loading: loadingRecommendedCourses,
    getData: recommendedCoursesPayload,
  } = useGetRequest(API_ENDPOINTS.RECOMMENDED_COURSES);
  useEffect(() => {
    setThumbnailURL(`${API_ENDPOINTS.GET_IMAGE}/what_is_mts_zz3GIl4hL.png`)
  })
  useEffect(() => {
    const fetchRecommendedCourses = async () => {
      if (!isLoading) {
        try {
          await recommendedCoursesPayload();
        } catch (err) {
          console.log("error:", err);
        }
      }
    };
    fetchRecommendedCourses();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (!loadingRecommendedCourses) {
        if (recommendedCoursesError) {
          console.log(
            "error while sending the post req:",
            recommendedCoursesError
          );
        } else if (recommendedCoursesData) {
          const mergeCourseData = (
            availableCoursesDetails,
            CourseStatDashboard
          ) => {
            return availableCoursesDetails.map((course) => {
              const courseStatDetails = CourseStatDashboard.find(
                (stat) => stat.course_id === course.course_id
              );
              return {
                ...course,
                avg_review: courseStatDetails?.avg_review,
              };
            });
          };

          const availableCourses = mergeCourseData(
            recommendedCoursesData.availableCoursesDetails || [],
            recommendedCoursesData.CourseStatDashboard || []
          );

          setCourseData({ availableCoursesDetails: availableCourses });
        }
      }
    }
  }, [
    isLoading,
    recommendedCoursesData,
    loadingRecommendedCourses,
    recommendedCoursesError,
  ]);

  // Use effect to check user data on every render
  useEffect(() => {
    if (!isLoading && userData && userData.length > 0) {
      const userObject = userData.find(
        (item) => typeof item === "object" && "user_id" in item
      );
      if (userObject && userObject !== userInfo) {
        setUserInfo(userObject);
      }
    }
  }, [userData, isLoading, userInfo]);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  let fullName = "";
  if (userInfo) {
    fullName = `${userInfo.first_name}${" "}${userInfo.last_name}${" "}`;
  }

  // console.log("Recommended course data from api:", courseData);
  return (
    <Box className="dashbord-page">
      {userInfo ? (
        <Header
          title={
            <>
              Hello, <span style={{ color: "#39CCCC" }}>{fullName}</span>
              👋
            </>
          }
          subtitle="Continue learning Life Skill with MTS "
        />
      ) : (
        <Skeleton
          variant="text"
          sx={{ width: "55%", height: "60%", marginTop: "20vh" }}
        />
      )}

      {/* video card for mts  */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h4" sx={{ color: "black", marginBottom: 2 }}>
            What is MTS?
          </Typography>
          <Box
            sx={{
              borderRadius: "8px",
              minHeight: "360px",
              backgroundImage: `url('${thumbnailURL}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >

            <VideoPlayerDashboard
              videoUrl={"v3rflEuzHdw"}
              thumbnailUrl={thumbnailURL}
            />
          </Box>
        </Grid>
      </Grid>
{/* 
      {courseData.availableCoursesDetails && courseData.availableCoursesDetails.length > 0 ? (
        <Box className="recommended-course">
          <AvailableCourses
            coursesText={"Recommended Courses"}
            availableCoursesDetails={courseData.availableCoursesDetails}
          />
        </Box>
      ) : loading ? (
        <Box
          sx={{
            marginTop: "16vh",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          {[...Array(2)].map((_, index) => (
            <SkeletonCard key={`row1-${index}`} />
          ))}
        </Box>
      ): ( <Typography></Typography>)} */}




      <GridCard />
    </Box>
  );
};

export default DashboardPage;
