import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import ChatArea from 'components/module/app/chatRoom/ChatArea';

const ChatRoomPage = () => {
  return (
    <>
      
      <CssBaseline />
        <ChatArea />
   
    </>
  );
};

export default ChatRoomPage;
