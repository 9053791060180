import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import QuizFeedback from "./QuizFeedback";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import QuizTimer from "./TimerCard";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizResultCard from "./QuizResultCard";

const FactOpinionQuiz = ({ content }) => {
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const { notifyUser } = useContext(NofityUserContext);
  const { data: saveQuizResponseData, postData: saveOptionQuizResponse } =
    usePostRequest(API_ENDPOINTS.SAVE_OPTION_QUIZ_PROGRESS);

  const [display, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [activeOptions, setActiveOptions] = useState({}); // Store selected options
  const [answerText, setAnswerText] = useState({}); // Store answer text
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [selectedOptionName, setSelectedOptionName] = useState("");
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(
        (item) => item.content_id === content
      );

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log(
      //   "lessonInfo is not available or does not contain content_id."
      // );
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails && contentDetails.factorOpinion) {
      setQuizData(contentDetails.factorOpinion[0]); // Set the first question as the initial quiz data
    }
  }, [contentDetails]);
  
  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.factorOpinion[0].quiz_op_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true)      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails---> ", quizStatApiResponse);
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_op_id === detail.response_quiz_op_id
        );
        
        // Ensure MasterQuizLongAnswer and MasterQuestion are defined
        const question = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.question
          : null;
        const questionScore = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.score
          : null;
  
        return {
          id: detail.response_quiz_op_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
          option_name: detail.MasterOption ? detail.MasterOption.option_name : null
        };
      });
      setModalData(combinedData);
      // console.log("combinedData op fact---> ", combinedData);
    }
  }, [quizStatApiResponse]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };


  useEffect(() => {
    if (contentDetails && contentDetails.factorOpinion.length > 0) {
      const firstQuestion = contentDetails.factorOpinion[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  const handleOptionClick = (option_id, option_name) => {
    setSelectedOptionName(option_name);
    if (quizData) {
      setActiveOptions((prev) => ({
        ...prev,
        [quizData.question_id]: option_id, // Update the selected option for the current question
      }));
    }
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());

  };

  const handleNavigation = (direction) => {
    if (quizData) {
      setSelectedOptionName("");
      const { question_id } = quizData;

      // Check if an option is selected
      if (!activeOptions[question_id]) {
        notifyUser(
          "Select Option",
          "Please select an option before moving to the next question.",
          "warning"
        );
        return;
      }

      // Check if at least one word is entered in the answer text
      if (
        !answerText[question_id] ||
        answerText[question_id].trim().length < 1
      ) {
        notifyUser(
          "Enter text",
          "Please enter at least one word before moving to the next question.",
          "warning"
        );
        return;
      }

      // Proceed to navigate to the next or previous question
      const currentIndex = contentDetails.factorOpinion.findIndex(
        (item) => item.question_id === question_id
      );

      let newQuestionIndex =
        direction === "next" ? currentIndex + 1 : currentIndex - 1;

      // Ensure the new question index is within valid range
      newQuestionIndex = Math.max(
        0,
        Math.min(newQuestionIndex, contentDetails.factorOpinion.length - 1)
      );

      // Set the new quiz data based on the new question index
      setQuizData(contentDetails.factorOpinion[newQuestionIndex]);
    }
  };

  const handleAnswerChange = (event) => {
    const { value } = event.target;
    if (quizData) {
      setAnswerText((prev) => ({
        ...prev,
        [quizData.question_id]: value, // Update answer text for the current question
      }));
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    try {
      // Validate all questions
      for (const question of contentDetails.factorOpinion) {
        const optionSelected = activeOptions[question.question_id];
        const answerProvided =
          answerText[question.question_id]?.trim().length >= 1;

        if (!optionSelected) {
          notifyUser(
            "Select Option",
            "Please select an option before moving to the next question.",
            "warning"
          );
          return;
        }

        if (!answerProvided) {
          notifyUser(
            "Enter text",
            "Please enter at least one word before moving to the next question.",
            "warning"
          );
          return;
        }
      }

      // If all questions are valid, proceed to save
      const responses = contentDetails.factorOpinion.map((question) => {
        return {
          course_id: lessonIdentifiers.course_id,
          quiz_op_id: question.quiz_op_id,
          quiz_id: question.question_id,
          option_id: activeOptions[question.question_id] || "", // Use the selected option or leave it blank
          answer_text: answerText[question.question_id] || "", // Use the answer text for each question
          completion_time:timeTakenInMinutes,
        };
      });

      // console.log("Saving responses:", responses);

      // Submit each response separately
      for (const response of responses) {
        await saveOptionQuizResponse(response);
      }

      // console.log("Submitted all responses:", responses);
      setQuizSubmitted(true);
    } catch (error) {
      console.error("Failed to submit responses:", error);
    }
  };

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.factorOpinion[0].quiz_op_id, lessonIdentifiers?.course_id)
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  const handleTimeUp = async () => {
    setActiveOptions({});
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
    data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizOption.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizOption
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box
          className="SAQ_wrapper"
          sx={{ borderTop: "170px solid #e4efff !important" }}
        >
          <Box className="SAQ_wrapper__Question-box">
            <Box className="SAQ_wrapper__Question-box--number">
              <Typography className="SAQ_wrapper__Question-box--number-text">
                Question{" "}
                {contentDetails.factorOpinion.findIndex(
                  (item) => item.question_id === quizData.question_id
                ) + 1}{" "}
                / {contentDetails.factorOpinion.length}
              </Typography>
            </Box>
            <Typography className="SAQ_wrapper__Question-box--question">
              {quizData.question}
            </Typography>
          </Box>
          <Box className="SAQ_wrapper__answer-wrapper">
            <Box className="SAQ_wrapper__options-wrapper">
              {estimatedCompletionTime && (
                <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp}/>
              )}
              <Grid container spacing={4}>
                {quizData.options && quizData.options.length > 0 ? (
                  quizData.options.map((option) => (
                    <Grid item xs={12} sm={6} key={option.option_id}>
                      <Button
                        fullWidth
                        className={`${activeOptions[quizData.question_id] ===
                          option.option_id
                          ? "SAQ_wrapper__options-wrapper--option-button--active"
                          : "SAQ_wrapper__options-wrapper--option-button"
                          }`}
                        onClick={() =>
                          handleOptionClick(
                            option.option_id,
                            option.option_name
                          )
                        }
                      >
                        {option.option_name}
                      </Button>
                    </Grid>
                  ))
                ) : (
                  <Typography>
                    No options available for this question
                  </Typography>
                )}
                {selectedOptionName && (
                  <Grid item xs={12}>
                    <Typography variant="text" className="SAQ_wrapper__guide">
                      Why is this {selectedOptionName.toLowerCase().startsWith('o') ? 'an' : 'a'} {selectedOptionName.toLowerCase()}? Please
                      explain below.
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <TextField
                    className="input-field"
                    required
                    fullWidth
                    name="answer"
                    id="answer"
                    value={answerText[quizData.question_id] || ""}
                    onChange={handleAnswerChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="SAQ_wrapper-navigate" id="no-bg">
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={() => handleNavigation("previous")}
                disabled={
                  contentDetails.factorOpinion.findIndex(
                    (item) => item.question_id === quizData.question_id
                  ) === 0
                }
              >
                Previous
              </Button>
              {contentDetails.factorOpinion.findIndex(
                (item) => item.question_id === quizData.question_id
              ) ===
                contentDetails.factorOpinion.length - 1 ? (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={handleSave}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              ) : (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={() => handleNavigation("next")}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
          <LinearProgress
            className="SAQ_wrapper__progress"
            variant="determinate"
            value={
              ((contentDetails.factorOpinion.findIndex(
                (item) => item.question_id === quizData.question_id
              ) +
                1) /
                contentDetails.factorOpinion.length) *
              100
            }
          />
        </Box>
      )}
    </>
  ) : (
    <Typography></Typography>
  );
};

export default FactOpinionQuiz;
