import { Box, Button } from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import QuizFeedback from "./QuizFeedback";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import LessonContext from "context/lessonContext/LessonContext";
import QuizTimer from "./TimerCard";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import QuizResultCard from "./QuizResultCard";

const HtmlCodeQuiz = ({ content }) => {
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const { message, notifyUser, clearUserNotification } =
  useContext(NofityUserContext);
  const [display, setLoading] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const questionRef = useRef(null);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  const { data: saveQuixResponseData, postData: saveQuixResponse } =
  usePostRequest(API_ENDPOINTS.SAVE_HTML_QUIZ_PROGRESS);

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const foundContent = lessonInfo.find(item => item.content_id === content);
      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        console.warn("Content not found for the provided content_id:", content);
      }
    } else {
      console.warn("lessonInfo is not available.");
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.htmlCodeDetails.quiz_ht_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);



useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);

      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails ht---> ", quizStatApiResponse);
  
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_ht_id === detail.response_ht_id
        );
  
        // Ensure MasterQuestion and StoryQuestion are defined
        const question = detail.MasterQuestion ? detail.MasterQuestion.question : '';
  
        return {
          id: detail.response_ht_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: detail.MasterQuestion ? detail.MasterQuestion.score : 0,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
  
      setModalData(combinedData);
      // console.log("combinedData ht---> ", combinedData);
    }
  }, [quizStatApiResponse]);


  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };


  const handleCellClick = (event) => {
    const cell = event.target;
    if (cell.classList.contains("html_structure_wrapper__html_table_selected")) {
      cell.classList.remove("html_structure_wrapper__html_table_selected");
      setSelectedCount(prevCount => prevCount - 1);
    } else {
      if (selectedCount < 5) {
        cell.classList.add("html_structure_wrapper__html_table_selected");
        setSelectedCount(prevCount => prevCount + 1);
      } else {
        alert("You can only select up to 5 squares.");
      }
    }
  };

  // Attach and cleanup event listeners for cell clicks
  useEffect(() => {
    const cells = document.querySelectorAll("#coloringBoxTable td");
    cells.forEach(cell => cell.addEventListener("click", handleCellClick));

    return () => {
      cells.forEach(cell => cell.removeEventListener("click", handleCellClick));
    };
  }, [selectedCount]);

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.htmlCodeDetails.quiz_ht_id, lessonIdentifiers?.course_id);      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handleSave = () => {
    setIsSaving(true);
    const htmlContent = questionRef.current.innerHTML;
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    const answers = [];

    // Find all the editable answer fields
    const answerFields = document.querySelectorAll(".html_structure_wrapper__html_table-input");

    answerFields.forEach((field) => {
      answers.push(field.innerText.trim());
    });

    const payload = {
      course_id: lessonIdentifiers.course_id,
      quiz_ht_id: contentDetails.htmlCodeDetails.quiz_ht_id,
      question: contentDetails.questions[0].question_id,
      answer_text: htmlContent,
      completion_time:timeTakenInMinutes,
    };

    const payloadArray = [payload];

    saveQuixResponse(payloadArray);
    setQuizSubmitted(true);
    notifyUser(
      "Quiz Submitted",
      "Your responses have been saved successfully!",
      "success"
    );
  
    // Send the payload array to the backend
  };


  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }
  const handleTimeUp = async () => {
    questionRef.current.innerHTML = "";
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
    data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  return display ? (
    <>
      {!quizStarted ? (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      ) : (
        <Box className="AFQ_wrapper">
          {contentDetails.htmlCodeDetails.estimated_completion_time && (
            <Box className="AFQ_wrapper__time">
              <QuizTimer key={timerKey} estimatedCompletionTime={contentDetails.htmlCodeDetails.estimated_completion_time} handleComplete={handleTimeUp}/>
            </Box>
          )}
          <Box
            ref={questionRef}
            className="AFQ_wrapper__long-question-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.questions[0]?.question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            ref={questionRef}
            className="AFO_wrapper__answer-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.htmlCodeDetails.html_question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            className="SAQ_wrapper-navigate"
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="SAQ_wrapper-navigate__button"
              variant="outlined"
              onClick={handleSave}
            >
             {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  ) : (<></>);
};

export default HtmlCodeQuiz;