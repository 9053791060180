import React, { useState, useContext } from "react";
import YouTube from 'react-youtube';
import LessonContext from "context/lessonContext/LessonContext";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const VideoPlayerDashboard = ({ videoUrl, thumbnailUrl }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);  // Track when video ends

  const handleProgress = ({ played }) => {
    const playedPercentage = played * 100;

    // If video is halfway played
    // if (playedPercentage >= 50) {
    //   // updateLessonCompletionData({
    //   //   status: 1,
    //   //   elementId: 16,
    //   //   timeSpent: 4,
    //   //   isRequired: false,
    //   // });
    // }
  };

  const handleEnded = () => {
    setIsEnded(true);  // Mark video as ended
    // updateLessonCompletionData({
    //   status: 1,
    //   elementId: 16,
    //   timeSpent: 100,
    //   isRequired: false,
    // });
  };

  const startPlaying = () => {
    setIsPlaying(true);
    setIsEnded(false);  // Reset end state when video restarts
  };

  const opts = {
    playerVars: {
      rel: 0,  // Disable recommendations from other channels
      modestbranding: 1,  // Minimal YouTube branding
      controls: 0, // Disable controls to hide "Watch on YouTube"
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {!isPlaying && (
        <div
          onClick={startPlaying}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            marginTop: "22vh",
            cursor: "pointer",
            backgroundImage: `url(${thumbnailUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="react-player__preview--image"
        >
          <PlayCircleFilledIcon style={{ fontSize: "64px", color: "#3CB371" }} />
        </div>
      )}

      {isPlaying && !isEnded && (
        <YouTube
          className="react-player_youtube"
          videoId={videoUrl}
          opts={opts}
          onProgress={handleProgress}
          onEnded={handleEnded}
          controls={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}

    </div>
  );
};

export default VideoPlayerDashboard;
