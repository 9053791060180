import React, { useContext, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import LessonContext from "context/lessonContext/LessonContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import { useNavigate, useParams } from "react-router-dom";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";

const NavigateChapter = ({ setIsContentLoading }) => {
  const { lesson: currentLessonSlug, chapter: currentChapterSlug } =
    useParams();
  const { message, notifyUser } = useContext(NofityUserContext);
  const navigate = useNavigate();
  const { sortedLessonData, lessonIdentifiers, lessonCompletionData } = useContext(LessonContext);
  const [sortedLessonInfo, setSortedLessonInfo] = useState([]);
  const [nextLessonSlug, setNextLessonSlug] = useState("");
  const [previousLessonSlug, setPreviousLessonSlug] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [currentLessonName, setCurrentLessonName] = useState("");
  const { setIsAppLoading } = useContext(LoadingContext);
  const [isLastLesson, setIsLastLesson] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [allViewed, setAllViewed] = useState(false);

  useEffect(() => {
    // Update `allViewed` whenever `lessonCompletionData` changes
    const isAllViewed = lessonCompletionData.every(item => item.is_viewed);
    setAllViewed(isAllViewed);
  }, [lessonCompletionData]);

  useEffect(() => {
    if (lessonCompletionData) {
      console.log('lessonCompletionData --->  ', lessonCompletionData);
    }
  },[])

  const {
    data: enrollmentData,
    loading: loadingEnrollmentData,
    error: enrollmentDataError,
    postData: enrollmentDataPayload,
  } = usePostRequest(API_ENDPOINTS.COURSE_ENROLLMENT_STATUS);

  useEffect(() => {
    if (sortedLessonData) {
      setSortedLessonInfo(sortedLessonData);
    }
  }, [sortedLessonData]);

  useEffect(() => {
    if (isLastLesson && !apiCalled) {
      enrollmentDataPayload({ course_id: lessonIdentifiers?.course_id, status: 'completed' });
      setApiCalled(true); // Mark the API as called
    }
  }, [isLastLesson, lessonIdentifiers, apiCalled]);

  const calculateLessonSlugs = () => {
    let previousLessonSlug = "";
    let nextLessonSlug = "";
    let chapterName = "";
    let activeChapter;
    let activeLessonIndex;
    let completedLessonsCount = 0;
    let currentLessonName = ""; // New variable for storing current lesson name
    let isLastLesson = false;

    for (const chapter of sortedLessonInfo) {
      const lessonIndex = chapter.lessons.findIndex(
        (lesson) => lesson.lesson_slug === currentLessonSlug
      );
      if (lessonIndex !== -1) {
        activeChapter = chapter;
        activeLessonIndex = lessonIndex;
        chapterName = chapter.chapter_name; // Assuming `chapter_name` is the chapter name field
        currentLessonName = chapter.lessons[lessonIndex].lesson_name; // Set the current lesson name
        completedLessonsCount = chapter.lessons.filter(
          (lesson) => lesson.isCompleted
        ).length;
        break;
      }
    }

    if (!activeChapter || activeLessonIndex === undefined) {
      // console.log("No active lesson found.");
      setChapterName("");
      setPreviousLessonSlug("");
      setNextLessonSlug("");
      setCompletionPercentage(0);
      setCurrentLessonName(""); // Clear current lesson name if no active lesson
      return;
    }

    const totalLessonsInChapter = activeChapter.lessons.length;
    const percentage = (completedLessonsCount / totalLessonsInChapter) * 100;
    setCompletionPercentage(percentage);

    if (activeLessonIndex > 0) {
      const previousLesson = activeChapter.lessons[activeLessonIndex - 1];
      previousLessonSlug = previousLesson.lesson_slug;
    } else {
      const previousChapterIndex =
        sortedLessonInfo.findIndex(
          (chap) => chap.chapter_id === activeChapter.chapter_id
        ) - 1;
      if (previousChapterIndex >= 0) {
        const previousChapter = sortedLessonInfo[previousChapterIndex];
        const previousLesson =
          previousChapter.lessons[previousChapter.lessons.length - 1];
        previousLessonSlug = previousLesson.lesson_slug;
      }
    }

    if (activeLessonIndex < activeChapter.lessons.length - 1) {
      // Get the next lesson in the current chapter
      const nextLesson = activeChapter.lessons[activeLessonIndex + 1];
      if (!nextLesson.isLocked) {
        nextLessonSlug = nextLesson.lesson_slug;
      }
    } else {
      // Check if there is a next chapter and get the first lesson of that chapter
      const nextChapterIndex =
        sortedLessonInfo.findIndex(
          (chap) => chap.chapter_id === activeChapter.chapter_id
        ) + 1;

      if (nextChapterIndex < sortedLessonInfo.length) {
        const nextChapter = sortedLessonInfo[nextChapterIndex];
        const nextLesson = nextChapter.lessons[0];
        if (!nextLesson.isLocked) {
          nextLessonSlug = nextLesson.lesson_slug;
        }
      } else {
        // If there are no more chapters or lessons, it is the last lesson
        isLastLesson = true;
      }
    }

    setChapterName(chapterName);
    setCurrentLessonName(currentLessonName); // Update the state with the current lesson name
    setPreviousLessonSlug(previousLessonSlug);
    setNextLessonSlug(nextLessonSlug);
    setIsLastLesson(isLastLesson);
  };

  useEffect(() => {
    calculateLessonSlugs();
  }, [sortedLessonInfo, currentLessonSlug]);

  const handleNextClick = () => {
    if (nextLessonSlug && !allViewed) {
      setIsAppLoading(true);
      setIsContentLoading(true);
      // console.log("Navigating to Next Lesson:", nextLessonSlug);
      navigate(`/app/course/${currentChapterSlug}/learn/${nextLessonSlug}`);
      setIsAppLoading(false);
      setIsContentLoading(false);
    } else {
      notifyUser(
        "Almost there!",
        "Please complete the current lesson before moving on to the next one.",
        "warning"
      );
    }
  };

  const handlePreviousClick = () => {
    if (previousLessonSlug) {
      setIsAppLoading(true);
      setIsContentLoading(true);
      // console.log("Navigating to Previous Lesson:", previousLessonSlug);
      navigate(`/app/course/${currentChapterSlug}/learn/${previousLessonSlug}`);
      setIsAppLoading(false);
      setIsContentLoading(false);
    } else {
      notifyUser(
        "Almost there!",
        "Please complete the current lesson before moving on to the next one.",
        "warning"
      );
    }
  };

  return (
    sortedLessonInfo.length > 0 && (
      <Box sx={{ width: "95%" }}>
        <Box className="navigate-chapter">
          <Button
            className="navigate-chapter__button"
            variant="outlined"
            onClick={handlePreviousClick}
            disabled={!previousLessonSlug} // Disable if no previous lesson
          >
            Previous
          </Button>
          <Typography className="navigate-chapter__title">
            {`${currentLessonName}` || "Chapter Name"}
          </Typography>

          <Button
            id="next"
            className="navigate-chapter__button"
            variant="outlined"
            onClick={handleNextClick}
            disabled={isLastLesson}
            sx={{
              backgroundColor: !nextLessonSlug ? '#f0f0f0' : '',
              color: !nextLessonSlug ? '#f0f0f0' : '',
            }}
          >
            Next
          </Button>
        </Box>
        <LinearProgress
          variant="determinate"
          className="navigate-chapter__progress"
          value={completionPercentage}
        />
      </Box>
    )
  );
};

export default NavigateChapter;
