import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Box,
  IconButton,
  Skeleton,
} from "@mui/material";
import ProfilePicture from "components/common/profilePicture/ProfilePicture";
import { profileFormSchema } from "config/schemas/settingsProfileSchema";
import EditIcon from "@mui/icons-material/Edit";
import UserContext from "context/userContext/UserContext";
import usePutRequest from "hooks/usePutRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { encryptData } from "utilityFunction/encryption&Decryption";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";

const Profile = ({ imageSize }) => {
  const fileInputRef = useRef(null);

  const { userData, isLoading, onLogin } = useContext(UserContext);

  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);
  const [localUserData, setLocalUserData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  //to display jan as the defualt month
  const sixYearsAgoJanuary = dayjs().subtract(6, "year").startOf("year");

  //state to display the edit icon on hover
  const [showEditIcon, setShowEditIcon] = useState(false);

  //put api request
  const {
    data: editUserInfo,
    loading: loadingUserInfo,
    error: errorEditingInfo,
    putData: editUserPayload,
  } = usePutRequest(API_ENDPOINTS.EDIT_USER_INFO_AND_SAVE);

  //useeffect to set the info from context to the local state
  useEffect(() => {
    if (!isLoading && userData && userData.length > 0) {
      const userObject = userData.find(
        (item) => typeof item === "object" && "user_id" in item
      );
      if (userObject && userObject !== userInfo) {
        setUserInfo(userObject);
        setLocalUserData({
          firstName: userObject.first_name,
          lastName: userObject.last_name,
          dateOfBirth: userObject.date_of_birth
            ? dayjs(userObject.date_of_birth, "DD-MM-YYYY")
            : null,
          email: userObject.email,
          // profilePic: userObject.profile_pic,
        });
      }
      // console.log("localUserData", localUserData);
    }
  }, [userData, isLoading]);
  
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const maxSize = 4 * 1024 * 1024; // 4 MB in bytes

  //   if (file) {
  //     if (file.size > maxSize) {
  //       notifyUser(
  //         "Update Failed",
  //         "File size exceeds the 4 MB limit. Please select a smaller file.",
  //         "warning"
  //       );
  //       return;
  //     }
  //     setFieldValue("profilePic", file);
  //     console.log("Selected file:", file);
  //   }
  // };
//  Handle submit form 
const handleFileChange = async (event) => {
  const file = event.target.files[0];
  const maxSize = 4 * 1024 * 1024; // 4 MB in bytes

  if (file) {
    if (file.size > maxSize) {
      notifyUser(
        "Update Failed",
        "File size exceeds the 4 MB limit. Please select a smaller file.",
        "warning"
      );
      return;
    }

    // Immediately update the UI with the new profile picture (preview)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      const imageUrl = event.target.result;

      // Update local state
      setLocalUserData((prevData) => ({
        ...prevData,
        profilePic: imageUrl,
      }));

      // Update context data
      onLogin({
        ...userInfo,
        profile_pic: imageUrl,
      });

      // Prepare FormData for upload
      const formData = new FormData();
      formData.append("profile_pic", file);

      try {
        // Call API to save the new profile picture
        const response = await editUserPayload(formData);

        if (response && response.profile_pic) {
          // Update the state with the URL returned by the server
          const updatedProfilePic = response.profile_pic;

          setLocalUserData((prevData) => ({
            ...prevData,
            profilePic: updatedProfilePic,
          }));

          onLogin({
            ...userInfo,
            profile_pic: updatedProfilePic,
          });

          notifyUser(
            "Update Success",
            "Profile picture updated successfully.",
            "success"
          );
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
        notifyUser(
          "Update Failed",
          "An error occurred while updating your profile picture.",
          "error"
        );
      }
    };
  }
};


const onSubmit = async (values) => {
    // console.log(values);
  
    const formData = new FormData();
    if (values.profilePic) {
      formData.append("profile_pic", values.profilePic);
      // console.log("profile pic appended to the formData:", formData);
  
      const reader = new FileReader();
      reader.readAsDataURL(values.profilePic);
      reader.onload = (event) => {
        const imageUrl = event.target.result;
        // console.log("image url", imageUrl);
  
        setLocalUserData((prevData) => ({
          ...prevData,
          profilePic: imageUrl,
        }));
        onLogin({
          ...userInfo,
          profile_pic: imageUrl,
        });
      };
    } else {
      // console.log("form value for profile pic is:", values.profile_pic);
    }
  
    const formattedValues = {
      ...values,
      dateOfBirth: values.dateOfBirth
        ? dayjs(values.dateOfBirth).format("DD-MM-YYYY").toString()
        : null,
    };
  
    try {
      const lowercasedEmail = formattedValues.email.trim().toLowerCase();
  
      const encryptedfirstName = encryptData(formattedValues.firstName.trim().toLowerCase());
      const encryptedlastName = encryptData(formattedValues.lastName.trim().toLowerCase());
      const encryptedEmail = encryptData(lowercasedEmail.trim().toLowerCase());
      const encryptedDOB = encryptData(formattedValues.dateOfBirth);
  
      formData.append("email", encryptedEmail);
      formData.append("first_name", encryptedfirstName);
      formData.append("last_name", encryptedlastName);
      formData.append("date_of_birth", encryptedDOB);
  
      // Wait for the API call to complete
      await editUserPayload(formData);
  
      // Now that the API call is successful, update the state and notify the user
      setLocalUserData((prevData) => ({
        ...prevData,
        firstName: formattedValues.firstName,
        lastName: formattedValues.lastName,
        email: formattedValues.email,
        dateOfBirth: dayjs(formattedValues.dateOfBirth, "DD-MM-YYYY"),
      }));
  
      onLogin((prevInfo) => ({
        ...prevInfo,
        user_id: userInfo.user_id,
        first_name: formattedValues.firstName,
        last_name: formattedValues.lastName,
        email: formattedValues.email,
        date_of_birth: formattedValues.dateOfBirth,
      }));
  
      // Notify the user of success
      notifyUser("Update Success", "User information updated successfully.", "success");
      // console.log("User info updated successfully");
  
    } catch (err) {
      console.log("error while updating the user info", err);
      notifyUser("Update Failed", "Error while updating user information.", "error");
    }
  };
  
  //formik initial values
  const initialValues = {
    profilePic: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    email: "",
  };

  //formik validation
  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    //local state
    initialValues: localUserData || initialValues,
    //validate the firstname,lastname,email and date of birth( client side validation)
    validationSchema: profileFormSchema,
    //onSubmit handler
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoading) {
    return <Skeleton variant="reactangle" />;
  }

  //function to trigger file input dialog
  const triggerFileInput = () => {
    fileInputRef.current.click();
    // console.log("trigger File Input");
  };

  return (
    <Box className="profile-wrapper">
      <Typography
        component="h1"
        variant="h5"
        className="profile-wrapper__header-text text"
      >
        Profile
      </Typography>

      <Box
        className="profile-form"
        component="form"
        noValidate
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <Box
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => setShowEditIcon(false)}
          sx={{
            position: "relative",
            display: "inline-block",
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: showEditIcon ? "blur(1px)" : "none",
              transition: "backdrop-filter 0.3s ease",
            },
          }}
        >
          <ProfilePicture
            className="profile-form__avatar"
            firstName={userInfo ? `${userInfo.first_name}` : "Loading..."}
            lastName={userInfo ? `${userInfo.last_name}` : "Loading..."}
            imageSize={imageSize}
            imageUrl={userInfo ? `${userInfo.profile_pic}` : null}
          />
          {showEditIcon && (
            <>
              <input
                hidden
                type="file"
                name="profilePic"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
              />
              {/* {!selectedFile && ( */}
              <IconButton
                color="#fff"
                aria-label="edit"
                size="small"
                id="attachfiles"
                name="attachfiles"
                onClick={triggerFileInput}
                autoComplete="attachfiles"
                sx={{
                  position: "absolute",
                  top: "51%",
                  left: "49%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                  backdropFilter: "none",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <EditIcon />
              </IconButton>
              {/* )} */}
            </>
          )}
        </Box>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel className="profile-form__input-label text">First Name</InputLabel>
            <TextField
              size="small"
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              type="text"
              id="firstName"
              value={values.firstName}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("firstName", e.target.value.trimStart());
              }}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue("firstName", e.target.value.trimStart());
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
              }}
            />
            {errors.firstName && touched.firstName ? (
              <Typography className="error-input_login">{errors.firstName}</Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel className="profile-form__input-label text">Email</InputLabel>
            <TextField
              size="small"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              type="email"
              value={values.email}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("email", e.target.value.trim());
              }}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue("email", e.target.value.trim());
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
              }}
            />
            {errors.email && touched.email ? (
              <Typography className="error-input_login">{errors.email}</Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel className="profile-form__input-label text">Last Name</InputLabel>
            <TextField
              size="small"
              required
              fullWidth
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="family-name"
              value={values.lastName}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("lastName", e.target.value.trimStart());
              }}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue("lastName", e.target.value.trimStart());
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
              }}
            />
            {errors.lastName && touched.lastName ? (
              <Typography className="error-input_login">{errors.lastName}</Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel className="profile-form__input-label text">
              Date of Birth
            </InputLabel>
            <DatePicker
              referenceDate={sixYearsAgoJanuary}
              format="DD/MM/YYYY"
              disableFuture
              value={values.dateOfBirth}
              onChange={(value) => {
                setFieldValue("dateOfBirth", value);
                setFieldTouched("dateOfBirth", true, false);
              }}
              onBlur={() => setFieldTouched("dateOfBirth", true)}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  required: true,
                  error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
                  InputProps: {
                    style: {
                      borderRadius: "8px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  },
                },
              }}
            />
            {errors.dateOfBirth && touched.dateOfBirth ? (
              <Typography className="error-input_login">
                {errors.dateOfBirth}
              </Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid container justifyContent="flex-start">
            <Grid item mt={4}>
              <Button
                type="submit"
                className="profile-form__button"
                variant="outlined"
                disabled={isSubmitting}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
