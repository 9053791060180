import React, { useState, useEffect } from "react";
import axios from "axios";
import { Popover, Box, Typography, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DictionaryTooltip = () => {
  const [selectedText, setSelectedText] = useState("");
  const [definition, setDefinition] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSelection = (event) => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText && selectedText.length > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelectedText(selectedText);
        setAnchorEl({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        });

        fetchDefinition(selectedText);
      }
    };

    // Add event listener to detect text selection
    document.addEventListener("mouseup", handleSelection);

    // Cleanup
    return () => {
      document.removeEventListener("mouseup", handleSelection);
    };
  }, []);

  const fetchDefinition = async (word) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);

      if (response.data && response.data.length > 0) {
        const firstMeaning = response.data[0].meanings[0];
        setDefinition({
          word: response.data[0].word,
          phonetic: response.data[0].phonetic || "",
          partOfSpeech: firstMeaning.partOfSpeech,
          definition: firstMeaning.definitions[0].definition,
        });
      }
    } catch (err) {
      setError("No definition found");
    } finally {
      setIsLoading(false);
    }
  };

  const closePopover = () => {
    setAnchorEl(null);
    setSelectedText("");
    setDefinition(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {open && (
        <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorEl}
          onClose={closePopover}
          PaperProps={{
            style: { padding: "12px", maxWidth: "300px" },
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Close Button */}
            <IconButton
              aria-label="Close"
              onClick={closePopover}
              style={{ alignSelf: "flex-end" }}
            >
              <CloseIcon />
            </IconButton>

            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <>
                <Typography variant="h6" fontWeight="bold">
                  {definition.word}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {definition.phonetic}
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic">
                  {definition.partOfSpeech}
                </Typography>
                <Typography variant="body2">{definition.definition}</Typography>
              </>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default DictionaryTooltip;
