import React from "react";
import { Box, Typography } from "@mui/material";
import CourseForumCard from "components/module/app/courseView/CourseForumCard";
import engageImage from "assets/images/engagewithyouclassnamtes.svg";
import CourseChatRoomCard from "components/module/app/courseView/CourseChatRoomCard";

const EngageCard = () => {
  return (
    <Box className="engage-card-wrapper">
      <Box className="engage-card-wrapper__image-wrapper">
        <Box
          className="engage-card-wrapper__image-wrapper--image"
          component="img"
          src={engageImage}
          alt="Engage with your classmates"
        />
        <Typography
          component="h5"
          className="engage-card-wrapper__image-wrapper--text"
        >
          Engage with your classmates
        </Typography>
      </Box>
      <Box className="engage-card-wrapper__card-holder">
        {/* <CourseForumCard /> */}
        <CourseChatRoomCard />
      </Box>
    </Box>
  );
};

export default EngageCard;
