import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { resetPasswordFormSchema } from "config/schemas/formSchema";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { encryptData } from "utilityFunction/encryption&Decryption";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment, InputLabel } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import mtsLogo from "assets/images/mts-logo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright(props) {
  return (
    <Typography
      variant="caption"
      align="center"
      className="footer-text"
      {...props}
    >
      {"Copyright ©2024 "}
      <Link
        className="text my-true-skill"
        to="https://mytrueskills.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        My True Skills
      </Link>{" "}
    </Typography>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);

  //state to store the form submission state
  const [formSubmitted, setFormSubmitted] = useState(false);

  //state to store the password
  const [showPassword, setShowPassword] = useState(false);

  //store the token in a var
  const { token } = useParams();


  // Improved token validation and error handling
  useEffect(() => {
    if (!token) {
      notifyUser(
        "Authentication Error",
        "Reset token is missing or invalid",
        "warning"
      );
      navigate("/login"); // Redirect if no token
    }
  }, [token, navigate, notifyUser]);

  //destructure the data from the hook
  const {
    data: resetPasswordData,
    loading: loadingResetPassword,
    error: resetPasswordError,
    postData: resetPasswordPayload,
  } = usePostRequest(API_ENDPOINTS.RESET_AUTH_PASSWORD);

  const onSubmit = async (values, actions) => {
    // Validate both passwords match before submission
    if (values.newPassword !== values.confirmPassword) {
      notifyUser(
        "Validation Error", 
        "New password and confirm password do not match", 
        "error"
      );
      return;
    }

    try {
      // Encrypt the new password
      const encryptedNewPassword = encryptData(values.newPassword);
      
      // Include the reset token in the payload
      await resetPasswordPayload({
        password_hash: encryptedNewPassword,
        reset_token: token // Add the token to the payload
      });

      // Set form submitted state
      setFormSubmitted(true);
    } catch (err) {
      console.error("Error in password reset:", err);
      notifyUser(
        "Password Reset Failed", 
        "An unexpected error occurred", 
        "error"
      );
    }
  };

  //function to handle the password toggle
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (formSubmitted && !loadingResetPassword) {
      if (resetPasswordData) {
        // Successful password reset
        notifyUser(
          "Password Reset Successful",
          "Your password has been reset successfully",
          "success"
        );
        navigate("/login");
      } else if (resetPasswordError) {
        // Failed password reset
        notifyUser(
          "Password Reset Failed", 
          resetPasswordError.toString() || "Unable to reset password", 
          "error"
        );
      }
    }
  }, [resetPasswordData, resetPasswordError, formSubmitted, navigate, notifyUser]);

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordFormSchema,
    onSubmit,
  });

  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    //local state
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    //validate the email and password( client side validation)
    validationSchema: resetPasswordFormSchema,
    //onSubmit handler
    onSubmit,
  });
  // console.log("is submitting", isSubmitting);
  console.log(errors);

  return (
    <Box className="auth-form-wrapper">
      <Box className="forgot-pwd__form-card" id="reset-password">
        <Box className="forget-pwd_form-wrapper">
          <img src={mtsLogo} alt="logo" className="forgot-pwd__card-logo" />
          <Typography
            component="h1"
            variant="h5"
            className=" text forgot-pwd__header-text"
          >
            Reset Password
          </Typography>
          <Typography variant="h6" className="text forgot-pwd__header-caption">
            To reset your password, Enter new password
          </Typography>
          <Box
            mt={2}
            component="form"
            className="forgot-pwd_form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container mb={2}>
              <Grid item xs={12}>
                <InputLabel className=" text input-field-label forgot-pwd__input-label">
                  New Password
                </InputLabel>
                <TextField
                  type={showPassword ? "text" : "password"}
                  size="small"
                  className="input-field"
                  required
                  fullWidth
                  placeholder="********"
                  id="newPassword"
                  name="newPassword"
                  autoComplete="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ mr: 1, width: "60%" }} />
                          ) : (
                            <Visibility sx={{ mr: 1, width: "60%" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.newPassword && touched.newPassword ? (
                  <Typography className="error-input_login">
                    {errors.newPassword}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className=" text input-field-label forgot-pwd__input-label--confirm-password">
                  Confirm Password
                </InputLabel>
                <TextField
                  type="password"
                  placeholder="********"
                  size="small"
                  className="input-field"
                  required
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <Typography className="error-input_login">
                    {errors.confirmPassword}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              className="button button-submit"
              id="reset-password-submit"
            >
              Submit
            </Button>
            <Grid container justifyContent="center" mt={4} mb={2}>
              <Grid item>
                <Typography className="text forgot-password">
                  <Link to="/login">
                    <NavigateBeforeIcon className="forgot-password__icon" />
                  </Link>
                  Back to
                  <Link to="/login" id="login">
                    {" "}
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
}
