import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import blueStar from "assets/icons/star-blue.svg";
import { formatDuration } from "utilityFunction/convertMinToHours";
import { API_ENDPOINTS } from "config/ApiConfig";
import { useFormik } from "formik";
import usePostRequest from "hooks/usePostRequest";
import { applyCouponSchema } from "config/schemas/applyCouponSchema";
import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import mtsLogo from "assets/images/mts-logo.png";
import { formatPrice } from "utilityFunction/addCommaToPrice";
import UserContext from "context/userContext/UserContext";
import { CouponSuccessMessage } from "./applyCoupon";
import ReviewCardSkeleton from "components/common/skeleton/ReviewCardSkeleton";
import LoadingOverlay from "overlay/loadingOverlay";

const CoursePriceCard = ({ lessonDetails }) => {
  //naviagte
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get('referral');

    if (referralCode) {
      setReferralCode(referralCode);
      // Automatically set the referral code as the applied coupon
      setAppliedCouponCode(referralCode);
      try {
        const payload = {
          code: referralCode,
          course_slug: storeSlug,
        };
        applyCouponPayload(payload);
        setCouponApplied(true);
        setLoadingCourseData(false);
      } catch (error) {
        console.error("Error applying coupon:", error);
      }
      setCouponApplied(true);
      setApplyCoupon(true);
      values.applyCouponCode = referralCode;
      notifyUser(
        "Coupon Applied",
        `Referral code ${referralCode} applied successfully!`,
        "success"
      );
    }
  }, [location.search]);



  const {
    loadingCourseInformation,
    courseInformation,

    setCourseSlug,
  } = useContext(CourseDetailsContext);

  //user context
  const { userData } = useContext(UserContext);
  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);
  const { slug } = useParams();

  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");

  //state to keep tract of the data being loaded to the variable
  const [loadingCourseData, setLoadingCourseData] = useState(true);

  //state to track the discount price after applying coupon
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  //local state to save the course info
  const [courseInfo, setCourseInfo] = useState({});

  //state to store the  create order data in the local state
  const [createOrderInfo, setCreateOrderInfo] = useState(null);

  //state to store the verify data from the api to the local state

  const [orderDataAfterVerfication, setOrderDataAfterVerfication] =
    useState(null);

  // used to add loading screen 
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);
  //state to store the razor pay payment id
  const [paymentID, setPaymentID] = useState("");

  //state to manage the apply coupon
  const [applyCoupon, setApplyCoupon] = useState(false);

  // Track if a coupon is applied
  const [couponApplied, setCouponApplied] = useState(false);

  //state to manage the coupon code
  const [couponCodePercentage, setCouponCodePercentage] = useState(0);

  //coupon code text value
  const [appliedCouponCode, setAppliedCouponCode] = useState("");

  const [processingPayment, setProcessingPayment] = useState(false);

  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [computedPrice, setComputedPrice] = useState('');

  const [referralCode, setReferralCode] = useState(null);

  const [cutOffPercentage, setPercentage] = useState(null);

  //initiate the apply coupon api
  const {
    data: applyCouponData,
    error: applyCouponError,
    loading: loadingApplyCoupon,
    postData: applyCouponPayload,
  } = usePostRequest(API_ENDPOINTS.APPLY_COUPON);

  //initiate the razorpay create order api
  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: createOrderPayload,
  } = usePostRequest(API_ENDPOINTS.RAYZORPAY_CREATE_ORDER);

  const {
    data: verifyOrderData,
    error: verifyOrderError,
    loading: loadingVerifyOrder,
    postData: verifyOrderPayload,
  } = usePostRequest(API_ENDPOINTS.RAYZORPAY_VERIFY_PAYMENT);

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (!loadingCourseInformation && courseInformation) {
      if (courseInformation) {
        setCourseInfo(courseInformation);
        setPercentage(courseInformation.cut_off_percentage);
        setLoadingCourseData(false);
      }
    }
  }, [courseInformation]);

  useEffect(() => {
    if (createOrderData) {
      setProcessingPayment(true);
      setCreateOrderInfo(createOrderData);
      setLoadingCourseData(false);
      setPaymentID(createOrderData.payment.payment_id);
    }
  }, [createOrderData]);

  // console.log("payment id : ", paymentID);
  //useeffect to store the verify order data in the local state
  useEffect(() => {
    if (verifyOrderData) {
      setOrderDataAfterVerfication(verifyOrderData);
      setLoadingCourseData(false);
    }
  }, [verifyOrderData]);
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const onSubmit = async (values) => {
    setApplyCoupon(true);
    setLoadingCourseData(true);
    try {
      const payload = {
        code: values.applyCouponCode,
        course_slug: storeSlug,
      };
      await applyCouponPayload(payload);
      setCouponApplied(true);
      setLoadingCourseData(false);
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  useEffect(() => {
    if (applyCouponError) {
      const errorMessage = applyCouponError.message || "An unknown error occurred";
      // console.log("error fetch discount", errorMessage);
      notifyUser(
        "Coupon Error",
        `${errorMessage}`,
        "error"
      );
      setCouponApplied(false);
      setAppliedCouponCode("");
    } else if (!loadingApplyCoupon && applyCouponData) {
      setCouponApplied(true);
      setAppliedCouponCode(values.applyCouponCode);
      setCouponCodePercentage(applyCouponData.discountPercentage);
      setPercentage((prev) => Number(prev) + Number(applyCouponData.discountPercentage || 0));
      setLoadingCourseData(false);
    }
  }, [applyCouponData, loadingApplyCoupon, applyCouponError]);

  useEffect(() => {
    if (couponCodePercentage > 0 && courseInfo.price) {
      const calculatedDiscountedPrice =
        courseInfo.price - (courseInfo.price * couponCodePercentage) / 100;
      setAppliedDiscount(calculatedDiscountedPrice.toFixed(0));
      setLoadingCourseData(false);
    }
  }, [couponCodePercentage, courseInfo.price]);

  const {
    handleBlur,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    //local state
    initialValues: {
      applyCouponCode: "",
    },
    validationSchema: applyCouponSchema,
    //onSubmit handler
    onSubmit,
  });

  const handleApplyCoupon = () => {
    setApplyCoupon(!applyCoupon);
  };

  useEffect(() => {
    if (orderDataAfterVerfication) {
      setProcessingPayment(false);
      navigate(`/app/payment/success/${paymentID}`);
      return;
    }
  }, [orderDataAfterVerfication]);

  // function handle rayzor payment
  const handlePayment = async () => {
    const intendedUrl = referralCode 
    ? `/app/course/${storeSlug}?referral=${referralCode}` 
    : `/app/course/${storeSlug}`;

   // Build the intended URL

    if (!userData.length > 0) {
      sessionStorage.setItem('intendedUrl', intendedUrl); // Store the intended URL in sessionStorage
      setOpenAuthModal(true);
      return;
    }

    if (!loadingCourseInformation && courseInfo && !loadingCreateOrder) {
      try {
        setProcessingPayment(true);
        const amount = appliedDiscount ? appliedDiscount : courseInfo.price;
        localStorage.setItem('coursePrice', amount);
        // navigate(`/app/course/${storeSlug}/purchase/${userData[0].first_name}-${userData[0].last_name}(${userData[0].username})`)
        const payload = {
          amount: amount.toString(),
          currency: "INR",
          course_id: courseInfo.course_id.toString(),
        };

        // Send the POST request
        await createOrderPayload(payload);
      } catch (err) {
        setProcessingPayment(false);
        if (err.response) {
          notifyUser(
            "Order Error",
            "An error occurred while creating the order.",
            "error"
          );
        } else if (err.request) {
          notifyUser(
            "Network Error",
            "Network error, please try again.",
            "warning"
          );
        } else {
          notifyUser(
            "Unexpected Error",
            `Unexpected error: ${err.message}`,
            "error"
          );
        }
      }
    }
  };

  const handleLogin = () => {
    setOpenAuthModal(false);
    navigate("/login");
  };

  const handleCreateAccount = () => {
    setOpenAuthModal(false);
    navigate("/create-account"); // or any other route for account creation
  };

  useEffect(() => {
    if (createOrderData) {
      setProcessingPayment(true);
      const amount = appliedDiscount ? appliedDiscount : courseInfo.price;
      const options = {
        key: userData.REACT_APP_RAYZOR_KEY,
        amount: amount * 100, // Razorpay expects amount in paise
        currency: "INR",
        name: "MTS LMS",
        description: courseInfo.course_name,
        image: mtsLogo,
        order_id: createOrderData.orderId,
        handler: async (response) => {
          // console.log("response : ", response);
          const verifyPayload = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          try {
            setLoadingOverlayVisible(true); // Show overlay
            await verifyOrderPayload(verifyPayload);
            razorpay.close();
          } catch (verificationError) {
            // console.log(verificationError);
          }
        },
        modal: {
          confirm_close: false,
          escape: true,
        },
        prefill: {
          name: "Student",
          email: "student@example.com",
          contact: "9876543210",
        },
        notes: {
          address: "MTS Life Skills Office",
        },
        theme: {
          color: "#39cccc",
        },
      };

      const razorpay = new window.Razorpay(options);

      razorpay.on("payment.failed", function (response) {
        // console.log("payment id inside the useefect handle payment:");
        console.error("Payment failed:", response.error);
        // window.location.href = `/app/payment/failure/${createOrderData.payment.payment_id}`;
        setProcessingPayment(false);
        setCourseSlug(storeSlug);
        // navigate(`/app/payment/failure/${createOrderData.payment.payment_id}`);
      });

      razorpay.open();
    }
  }, [createOrderData]);

  useEffect(() => {
    if (courseInfo.price && courseInfo.cut_off_percentage) {
      const formattedPrice = (courseInfo.price * (1 + (courseInfo.cut_off_percentage) / 100));
      const roundedPrice = Math.round(formattedPrice / 10) * 10;
      setComputedPrice(formatPrice(roundedPrice));
    } else {
      setComputedPrice(''); // Clear the state if values are invalid
    }
  }, [courseInfo]);

  return (
    <>
      {courseInfo ? (
        <Card className="course-price-wrapper">
          <Box className="course-price-wrapper__course-info">
            <Typography
              className="course-price-wrapper__course-info--title"
              variant="h6"
            >
              {courseInfo.course_name}
            </Typography>

            <Box className="course-price-wrapper__course-info__details">
              <Box className="course-price-wrapper__course-info__details-item">
                <Box
                  component="img"
                  src={barchart}
                  className="course-price-wrapper__course-info__details-icon"
                  id="barchart"
                  alt="barchart"
                />
                <Typography
                  className="course-price-wrapper__course-info__details-text"
                  variant="body2"
                >
                  {courseInfo.RefCourseDifficulty?.name || ""}
                </Typography>
              </Box>
              <Box className="course-price-wrapper__course-info__details-item">
                <Box
                  component="img"
                  src={blueStar}
                  className="course-price-wrapper__course-info__details-icon"
                  id="star"
                  alt="rating"
                />
                <Typography
                  className="course-price-wrapper__course-info__details-text"
                  variant="body2"
                >
                  {lessonDetails.avg_review}
                </Typography>
              </Box>

              <Box className="course-price-wrapper__course-info__details-item">
                <Box
                  component="img"
                  src={clock}
                  className="course-price-wrapper__course-info__details-icon"
                  alt="Duration"
                  id="clock"
                />
                <Typography
                  className="course-price-wrapper__course-info__details-text"
                  variant="body2"
                >
                  {formatDuration(courseInfo.duration)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="course-price-wrapper__details-list">
            <Typography
              variant="h6"
              className="course-price-wrapper__details-list--title"
            >
              This course includes
            </Typography>
            <List disablePadding>
              <ListItem
                disablePadding
                className="course-price-wrapper__details-list--item"
              >
                <ListItemIcon sx={{ minWidth: "0", mr: 1 }}>
                  <CheckIcon className="course-price-wrapper__details-list--icon" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" className="course-price-wrapper__details-list--text">
                      Certificate upon completion
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: "0", mr: 1 }}>
                  <CheckIcon className="course-price-wrapper__details-list--icon" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" className="course-price-wrapper__details-list--text">
                      Access to MTS Community
                    </Typography>
                  }
                />
              </ListItem>
            </List>

            <Box className="course-price-wrapper__price-details">
              <Box className="course-price-wrapper__price-details-wrapper">
                <Typography className="course-price-wrapper__price-details--price-now">
                  {appliedDiscount
                    ? formatPrice(appliedDiscount)
                    : formatPrice(courseInfo.price)}
                </Typography>
                <Typography className="course-price-wrapper__price-details--price-before">
                  {computedPrice }

                </Typography>
              </Box>
                {courseInfo.cut_off_percentage && (
              <Box className="course-price-wrapper__price-details--discount-wrapper">
                  <Typography className="course-price-wrapper__price-details--discount-wrapper--discount">
                    {cutOffPercentage}% OFF
                  </Typography>

              </Box>
                )}
            </Box>
            <Box className="course-price-wrapper__button-wrapper">
              <Button
                onClick={handlePayment}
                fullWidth
                variant="outlined"
                className="course-price-wrapper__button-wrapper--buy-now"
              >
                Buy Now
              </Button>

              <Typography className="course-price-wrapper__button-wrapper--apply-coupon">
                {couponApplied && appliedCouponCode ? (
                  <Box className="align-center">
                    <CouponSuccessMessage
                      couponCode={appliedCouponCode}
                      onClose={() => {
                        setCouponApplied(false);
                        setAppliedCouponCode("");
                        setApplyCoupon(false);
                        values.applyCouponCode = "";
                        setAppliedDiscount(0);
                        notifyUser(
                          "Coupon Removed",
                          "The applied coupon has been removed successfully.",
                          "success"
                        );
                      }}
                    />
                  </Box>
                ) : applyCoupon ? (
                  <Box
                    className="align-center"
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="applyCouponCode"
                      name="applyCouponCode"
                      placeholder="Enter Coupon"
                      value={values.applyCouponCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        style: {
                          width: "100%",
                          overflow: "hidden",
                          borderRadius: "15px",
                          backgroundColor: "#fff",
                          fontFamily: "Gilroy-Medium, sans-serif",
                          padding: "0.5vh 0vh",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="coupon" edge="end">
                              <Button
                                type="submit"
                                disabled={isSubmitting}
                                sx={{
                                  color: "#39CCCC",
                                  fontFamily: "Gilroy-Medium, sans-serif",
                                  textTransform: "none",
                                  paddingRight: "3vh",
                                }}
                              >
                                Apply
                              </Button>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.applyCouponCode && touched.applyCouponCode ? (
                      <Typography className="error-input_login">
                        {errors.applyCouponCode}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Link
                    className="course-price-wrapper__button-wrapper--apply-coupon"
                    onClick={handleApplyCoupon}
                  >
                    Apply Coupon
                  </Link>
                )}
              </Typography>
            </Box>
          </Box>
          {loadingOverlayVisible && <LoadingOverlay message="Processing payment..." />}

          {/* Modal for authentication options */}
          <Dialog open={openAuthModal} onClose={() => setOpenAuthModal(false)}>
            <DialogTitle>Login Required</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You need to log in or create an account to proceed with the payment.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCreateAccount} color="primary">
                Create Account
              </Button>
              <Button onClick={handleLogin} color="primary" autoFocus>
                Login
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      ) : (
        <ReviewCardSkeleton />
      )}
    </>
  );
};

export default CoursePriceCard;
