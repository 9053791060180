import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { PDFReader } from "components/module/app/courseChapter/PDFReader";
import LessonContext from "context/lessonContext/LessonContext";
import VideoPlayerSkeleton from "components/common/skeleton/videoPlayerSkeleton";

const PDFComp = ({content }) => {
  const { lessonInfo } = useContext(LessonContext);
  const [pdfFileName, setPdfFileName] = useState("");
  const [contentDetails, setContentDetails] = useState(null);

  useEffect(() => {
    
    if (lessonInfo) {
      const contentArray = lessonInfo || []; 
      const foundContent = contentArray.find(item => item.content_id === content);
  
      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log("lessonInfo is not available or does not contain content_id.");
    }
  }, [lessonInfo, content]); 

  useEffect(() => {
    if (contentDetails) {
        // Get the PDF file name from the MasterPDF object and ensure it's a string
        const fileName = String(contentDetails.MasterPDF.name);
        setPdfFileName(fileName);
      
    }
  }, [contentDetails]);

  return pdfFileName ? (
    <Box className="pdf-component">
      <PDFReader pdf={pdfFileName} content={content} />
    </Box>
  ) : (
    <VideoPlayerSkeleton />
  );
};

export default PDFComp;
