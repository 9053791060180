import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineContent from "@mui/lab/TimelineContent";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import checkCircle from "assets/icons/check-circle-completed.svg";
import transparentCircle from "assets/icons/blank-circle.svg";
import useSortedCourseData from "hooks/useSortedCourseData";
import { Link, useParams } from "react-router-dom";
import LoadingContext from "context/loadingContext/LoadingContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
// import CourseProgressCircle from "components/common/progress/CircularProgress";
// import courseforum from "assets/icons/course-forum.png";

const CourseTimeline = () => {
  //store the slug in a var
  const { chapter } = useParams();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");
  const [apiCalled, setApiCalled] = useState(false);

  const {
    setLessonInfo,
    lessonCompletionData,
    storeLessonIdentifiers,
    lessonIdentifiers,
    setSortedLessonData,
    sortedLessonData
  } = useContext(LessonContext);

  const {
    data: enrollmentData,
    loading: loadingEnrollmentData,
    error: enrollmentDataError,
    postData: enrollmentDataPayload,
  } = usePostRequest(API_ENDPOINTS.COURSE_ENROLLMENT_STATUS);


  //store the course chapters details
  const [courseChapter, setCourseChapter] = useState([]);
  //loading global context
  const { isAppLoading } = useContext(LoadingContext);

  //store the slub in a usestate
  useEffect(() => {
    if (chapter) {
      setStoreSlug(chapter);
    }
  }, [chapter]);

  //trigger the get req for the chapters timeline
  const {
    data: chapterData,
    error: chapterError,
    loading: loadingChapter,
    getData: chapterPayload,
  } = useGetRequest(API_ENDPOINTS.USER_COURSE_CHAPTER);

  useEffect(() => {
    const fetchChapter = async () => {
      if (!isAppLoading && storeSlug) {
        try {
          await chapterPayload(storeSlug);
        } catch (err) {
          // console.log("error with the get request api call");
        }
      }
    };
    fetchChapter();
  }, [isAppLoading, storeSlug]);

  useEffect(() => {
    if (!loadingChapter) {
      if (chapterError) {
        // console.log("error fetching the chapter error:", chapterError);
      } else if (chapterData) {
        //  Convert chapterData object to an array
        setCourseChapter(Object.values(chapterData));
        if (chapterData.courseStat && chapterData.courseStat.course_id) {
          storeLessonIdentifiers({
            course_id: chapterData.courseStat.course_id,
          });
        }
      }
    }
  }, [chapterData, loadingChapter, chapterError]);

  useEffect(() => {
    if (chapterData && chapterData.status === 'enrolled' && !apiCalled && lessonIdentifiers?.course_id) {
      enrollmentDataPayload({ course_id: lessonIdentifiers?.course_id, status: 'in-progress' });
      setApiCalled(true); // Mark the API as called
    }
  }, [chapterData, lessonIdentifiers, apiCalled]);

  // Always call the hook, but handle when data is not available
  const {
    sortedCourseData: sortedChapter,
    totalLessons,
    completedLessons,
    completionPercentage,
  } = useSortedCourseData(courseChapter);

  useEffect(() => {
    if (sortedChapter) {
      setSortedLessonData(sortedChapter);
    }
  }, [sortedChapter]);

  return (
    sortedLessonData && (
      <Box className="course-timeline-wrapper">
        {sortedLessonData.map((chapter) => (
          <Accordion
            key={chapter.chapter_id}
            className="course-timeline-wrapper__accordian"
          >
            <AccordionSummary
              className="course-timeline-wrapper__accordian--summary"
              id={`panel${chapter.chapter_id}-header`}
              aria-controls={`panel${chapter.chapter_id}-content`}
              expandIcon={<ExpandMoreIcon />}
            >
              <Box
                component="img"
                src={chapter.isCompleted ? checkCircle : transparentCircle}
                alt="chapter-status-icon"
                className="course-timeline-wrapper__circle-icon"
                id={chapter.isCompleted ? "" : "transparent-circle"}
              />
              <Typography
                className="course-timeline-wrapper__title"
                variant="body2"
              >
                {chapter.chapter_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Timeline
                className="course-timeline-timeline"
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {chapter.lessons.map((lesson, index) => (
                  <TimelineItem
                    key={lesson.lesson_id}
                    className="course-timeline-timeline__item"
                  >
                    <TimelineSeparator>
                      <Box
                        component="img"
                        src={
                          lesson.isCompleted ? checkCircle : transparentCircle
                        }
                        alt="lesson-status-icon"
                        className="course-timeline-wrapper__circle-icon-list"
                        id={lesson.isCompleted ? "" : "transparent-circle"}
                      />
                      {index < chapter.lessons.length - 1 && (
                        <TimelineConnector className="course-timeline-timeline__item--connector" />
                      )}
                    </TimelineSeparator>
                    <TimelineContent className="course-timeline-timeline__item--content">
                      <Box className="timeline-content-wrapper">
                        {lesson.isLocked ? (
                          <Typography className="timeline-content-wrapper__text locked">
                            {lesson.lesson_name}
                          </Typography>
                        ) : (
                          <Link
                            to={`/app/course/${storeSlug}/learn/${lesson.lesson_slug}`}
                            className="timeline-content-wrapper__link"
                          >
                            <Typography
                              className={`timeline-content-wrapper__text`}
                              id="cursor"
                            >
                              {lesson.lesson_name}
                            </Typography>
                          </Link>
                        )}
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    )
  );
};

export default CourseTimeline;

// <Accordion className="course-timeline-wrapper__accordian">
{
  /* <AccordionSummary
className="course-timeline-wrapper__accordian--summary"
id="panel1-header"
aria-controls="panel1-content"
expandIcon={<ExpandMoreIcon />}
>
<Box
  component="img"
  src={courseforum}
  id="transparentcircle"
  alt="completed-icon"
  className="course-timeline-wrapper__circle-icon"
/>
<Typography
  className="course-timeline-wrapper__title"
  variant="body2"
>
  Course Forum
</Typography>
</AccordionSummary>
</Accordion> */
}
