import React, { useContext, useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { Box } from "@mui/material";
import { API_ENDPOINTS } from "config/ApiConfig";
import VideoPlayerSkeleton from "components/common/skeleton/videoPlayerSkeleton";
import LessonContext from "context/lessonContext/LessonContext";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export const PDFReader = (pdf) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const { updateLessonCompletionData } = useContext(LessonContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pdf && pdf.pdf) {
      const pdfFileName = pdf.pdf;
      setPdfUrl(`${API_ENDPOINTS.GET_PDF}/${pdfFileName}`);
    }
  }, [pdf]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setIsLoading(false);
  }

  function changePage(offset) {
    const newPageNumber = pageNumber + offset;
    setPageNumber(newPageNumber);
    setIsLoading(true); // Start showing skeleton when switching pages

    // // Check if user has viewed 50% of the PDF
    // if (newPageNumber >= Math.ceil(numPages * 0.5)) {
    //   console.log("User has viewed more than 50% of the PDF");

    //   updateLessonCompletionData({
    //     isRequired: true,
    //     status: 1,

    //     // elementId: pdf.elementId, // Ensure this ID is unique to this PDF component
    //     elementId: 3,
    //     timeSpent: newPageNumber,
    //   });
    // }
  }

  function previousPage() {
    changePage(-1);
    setIsLoading(false);
  }

  function nextPage() {
    changePage(1);
    setIsLoading(false);
  }

  function onPageLoadSuccess() {
    setIsLoading(false); // Stop loading once the new page has fully rendered
  }

  return pdfUrl ? (
    <Box
      sx={{ width: "100%", height: "100%", position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<VideoPlayerSkeleton />}
      >
        {isLoading ? (
          <VideoPlayerSkeleton /> // Show skeleton while loading
        ) : (
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onRenderSuccess={onPageLoadSuccess} // Hide skeleton when page is ready
          />
        )}
      </Document>
      <div className="page-controls" style={{ opacity: isHovered ? 1 : 0 }}>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          ‹
        </button>
        <span>
          {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </span>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          ›
        </button>
      </div>
    </Box>
  ) : (
    <Box sx={{ marginTop: 4 }}>
      <VideoPlayerSkeleton />
    </Box>
  );
};

export default PDFReader;