import React, { useState } from 'react';
import styled from 'styled-components';

const GameContainer = styled.div`
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background: linear-gradient(135deg, #84fab0, #8fd3f4);
    color: #fff;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        max-width: 1200px;
        padding: 2rem;
        border-radius: 20px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
        color: #333;
    }

    .left-items, .right-items {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 35%;
    }

    .center-words {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding: 1rem;
        border-radius: 15px;
        gap: 3rem;
        word-spacing: 5px;
    }

    .item {
        position: relative;
        width: 80%;
        aspect-ratio: 1/1;
        border: 2px solid #e0e0e0;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
    }

    .item img {
        width: 50%;
        height: 50%;
        object-fit: contain;
    }

    .item:hover {
        transform: scale(1.1);
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        background-color: #f0f8ff;
    }

    .word {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0.8rem;
        color: #357abd;
        padding: 0.2rem 2rem;
        border-radius: 10px;
        text-align: center;
        transition: background 0.3s ease, transform 0.3s ease;
    }

    .word:hover {
        background: #8fd3f4;
        transform: translateY(-2px);
    }

    .matched {
        background: #4caf50;
        color: #fff;
        border-color: #4caf50;
        pointer-events: none;
        box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
    }

    .selected {
        background: #e3f2fd;
        border-color: #4a90e2;
    }

    h1 {
        position: absolute;
        top: 0.1rem;
        left: 49%;
        transform: translateX(-50%);
        font-size: 1.5rem;
        color: #357abd;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
`;

const MatchTheFollowing = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedWord, setSelectedWord] = useState(null);
    const [matchedPairs, setMatchedPairs] = useState(new Set());

    const items = [
        { match: 'pencil', name: 'Pencil' },
        { match: 'rubber', name: 'Rubber' },
        { match: 'book', name: 'Book' },
        { match: 'ruler', name: 'Ruler' },
        { match: 'sharpener', name: 'Sharpener' },
        { match: 'pen', name: 'Pen' },
        { match: 'crayon', name: 'Crayon' },
        { match: 'glue', name: 'Glue' },
        { match: 'school-bag', name: 'School-Bag' },
        { match: 'scissors', name: 'Scissors' }
    ];

    const handleItemClick = (item) => {
        if (matchedPairs.has(item.match)) return;

        if (selectedItem?.match === item.match) {
            setSelectedItem(null);
            return;
        }

        setSelectedItem(item);
        checkMatch(item);
    };

    const handleWordClick = (word) => {
        if (matchedPairs.has(word.toLowerCase())) return;

        if (selectedWord === word) {
            setSelectedWord(null);
            return;
        }

        setSelectedWord(word);
        checkMatch(word);
    };

    const checkMatch = (selected) => {
        if (!selectedItem || !selectedWord) return;

        const itemMatch = selectedItem.match;
        const wordText = selectedWord.toLowerCase();

        if (itemMatch === wordText) {
            setMatchedPairs(prev => new Set([...prev, itemMatch]));
            setSelectedItem(null);
            setSelectedWord(null);

            if (matchedPairs.size === 9) {
                setTimeout(() => {
                    alert('Congratulations! You matched all items correctly!');
                }, 500);
            }
        }
    };

    return (
        <GameContainer>
            
            <div className="container">
            <h1>Match the Following</h1>
                <div className="left-items">
                    {items.slice(0, 5).map((item) => (
                        <div 
                            key={item.match}
                            className={`item ${selectedItem?.match === item.match ? 'selected' : ''} ${matchedPairs.has(item.match) ? 'matched' : ''}`}
                            onClick={() => handleItemClick(item)}
                        >
                            <img 
                                src={`/${item.name}.png`} 
                                alt={item.name} 
                            />
                        </div>
                    ))}
                </div>
                
                <div className="center-words">
                    {items.map((item) => (
                        <div 
                            key={item.name} 
                            className={`word ${selectedWord?.toLowerCase() === item.match ? 'selected' : ''} ${matchedPairs.has(item.match) ? 'matched' : ''}`}
                            onClick={() => handleWordClick(item.name)}
                        >
                            {item.name.toUpperCase()}
                        </div>
                    ))}
                </div>

                <div className="right-items">
                    {items.slice(5).map((item) => (
                        <div 
                            key={item.match}
                            className={`item ${selectedItem?.match === item.match ? 'selected' : ''} ${matchedPairs.has(item.match) ? 'matched' : ''}`}
                            onClick={() => handleItemClick(item)}
                        >
                            <img 
                                src={`/${item.name}.png`} 
                                alt={item.name} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </GameContainer>
    );
};

export default MatchTheFollowing;