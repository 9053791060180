import React, { useEffect, useState, memo } from "react";
import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import quizSuccessBg from "assets/images/quizSuccessBg.png";
import quizSuccessIcon from "assets/images/quizSuccessIcon.png";
import { FaCheck } from "react-icons/fa6";
import Confetti from "react-confetti";
import { useWindowSize } from 'react-use'; 

const QuizFeedback = ({ success }) => {
    const [quizDataSuccess, setQuizData] = useState(null);
    const { width, height } = useWindowSize();

    // List of random phrases
    const phrases = [
        "Amazing!",
        "Great Job!",
        "Fantastic!",
        "You're Awesome!",
        "Brilliant!",
        "Nice Work!",
        "Outstanding!",
    ];

    // Function to randomly select a phrase
    const getRandomPhrase = () => {
        return phrases[Math.floor(Math.random() * phrases.length)];
    };

    useEffect(() => {
        if (success) {
            // Simulate fetching lessonInfo data and setting the relevant quiz object
            setQuizData(true);
        }
    }, [success]);

    return quizDataSuccess ? (
        <Box className="SAQ_wrapper quiz_feedback"
            sx={{
                backgroundImage: `url(${quizSuccessBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: '100vh', // Ensure it takes full height
                paddingTop: { xs: '20%', sm: '10%', md: '5%' }, // Adjust padding based on screen size
            }}
        >
            <Confetti width={width} height={height} />
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    padding: 4,
                    borderRadius: 2,
                }}
            >
                <Grid className="SAQ_wrapper__options-wrapper--option-icon-g--active" sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: "scale-up 1.5s ease-in-out infinite",
                    '@keyframes scale-up': {
                        '0%, 100%': { transform: 'scale(1)' },
                        '50%': { transform: 'scale(1.2)' },
                    },
                }}> 
                    <FaCheck />
                </Grid>
                <Grid item>
                    <Typography className="quiz_feedback_title" sx={{
                        fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive font size
                        marginBottom: 1, // Add margin for spacing
                    }}>
                        {getRandomPhrase()}
                    </Typography>
                    <Typography className="quiz_feedback_para" sx={{
                        fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
                        marginTop: { xs: 2, sm: 1 }, // Adjust spacing
                    }}>
                        Great job completing the quiz! Let's continue the learning
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    ) : (
        <Typography></Typography>
    );
};

export default memo(QuizFeedback);