import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import React, { useContext, useState, useEffect } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import QuizFeedback from "./QuizFeedback";
import QuizTimer from "./TimerCard";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizResultCard from "./QuizResultCard";

const AnswerField = ({ content }) => {
  const { message, notifyUser } = useContext(NofityUserContext);
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [display, setLoading] = useState(false);
  const course_id = lessonIdentifiers.course_id;
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { data: quizStatResponse, postData: quizStatPayload } =
  usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(
        (item) => item.content_id === content
      );

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log(
      //   "lessonInfo is not available or does not contain content_id."
      // );
    }
  }, [lessonInfo, content]);

  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: postLongQuizAnswer,
  } = usePostRequest(API_ENDPOINTS.SAVE_LONG_ANSWER_QUIZ);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails---> ", quizStatApiResponse);
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_la_id === detail.response_la_id
        );
        
        // Ensure MasterQuizLongAnswer and MasterQuestion are defined
        const question = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.question
          : null;
        const questionScore = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.score
          : null;
  
        return {
          id: detail.response_la_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
      setModalData(combinedData);
      // console.log("combinedData---> answer ", combinedData);
    }
  }, [quizStatApiResponse]);
  

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };

  useEffect(() => {
    if (contentDetails && contentDetails.length > 0) {
      const firstQuestion = contentDetails[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  const questions = contentDetails || [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(""));

  const countWords = (text) =>
    text
      ? text
        .trim()
        .split(/\s+/)
        .filter((word) => word).length
      : 0;

  const wordCount = countWords(answers[activeQuestionIndex]);
  const minWords = questions[activeQuestionIndex]?.minWords || 0;

  const handleInputChange = (event) => {
    let updatedAnswer = event.target.value;

    // Check if the text starts with a dot or any special character
    if (/^[\.\W]/.test(updatedAnswer)) {
      notifyUser("Invalid Input", "Text should not start with a dot or special character.", "warning");
      // Remove the leading special character (dot in this case)
      updatedAnswer = updatedAnswer.slice(1); 
    }
    const updatedAnswers = [...answers];
    updatedAnswers[activeQuestionIndex] = event.target.value;
    setAnswers(updatedAnswers);
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handleNextQuestion = async () => {
    setIsSaving(true);
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    if (countWords(answers[activeQuestionIndex]) < minWords) {
      notifyUser(
        "Minimum words",
        "Doesn't meet minimum words requirement",
        "warning"
      );
      return;
    }

    if (activeQuestionIndex < questions.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    } else {
      try {
        for (let i = 0; i < questions.length; i++) {
          await postLongQuizAnswer({
            course_id: course_id,
            quiz_id: questions[i].question_id,
            answer_text: answers[i],
            quiz_la_id: questions[i].quiz_la_id,
            completion_time:timeTakenInMinutes,
          });
        }
        setQuizSubmitted(true);
      } catch (error) {
        console.error("Error submitting answers:", error);
      }
    }
  };

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id)
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (questions.length === 0) {
    return <Typography></Typography>;
  }

  const handleTimeUp = async () => {
    setAnswers("");
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
    data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box className="AFQ_wrapper">
          <Box className="AFQ_wrapper__time">
            {estimatedCompletionTime && (
              <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp}/>
            )}
          </Box>
          <Box
            className="AFQ_wrapper__long-question-wrapper"
            dangerouslySetInnerHTML={{
              __html: questions[activeQuestionIndex]?.question.replace(
                /&nbsp;/g,
                " "
              ),
            }}
          />
          <Box className="AFO_wrapper__answer-wrapper" sx={{ flex: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  className="input-field"
                  required
                  fullWidth
                  multiline
                  minRows={10}
                  value={answers[activeQuestionIndex]}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ display: "block", textAlign: "left", mt: 1 }}
                >
                  {wordCount}/{minWords} Words
                </Typography>
              </Grid>
            </Grid>
            <Box
              className="SAQ_wrapper-navigate"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                onClick={handleNextQuestion}
                disabled={countWords(answers[activeQuestionIndex]) < minWords}
                sx={{ ml: questions.length > 1 ? 2 : 0 }}
              >
               {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  ) : (
    <></>
  );
};

export default AnswerField;
